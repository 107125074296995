UploadFileFtr = null
### @ngInject ###
UploadFileFtr = ($cordovaFile, $http, AlertService, ConfigCnst, gettext) ->
    data =
        p1: ''
        p2: ''
    server  = encodeURI ConfigCnst.serverUrl+'/images'
    options = null

    if ionic.Platform.isWebView()
        options = new FileUploadOptions()
        options.chunkedMode = false
        options.mimeType    = 'image/jpeg'
        options.params      =
            type: 'mobile'
            description: 'Uploaded from my phone'

    service =
        response: ''

        upload: (filePath, progressUpdate) ->
            sm = @

            $cordovaFile.uploadFile server, filePath, options
            .then (res) ->
                sm.response = res
                return
            , (err) ->
                if err.code and err.source
                    error = gettext "[#{err.code}] Fail upload file #{err.source} to server"
                else
                    error = gettext 'Upload photo request failed'
                AlertService.showError error
                return
            , (progress) ->
#                p =
#                    bubbles: false
#                    cancelBubble: false
#                    cancelable: false
#                    lengthComputable: true
#                    loaded: 214684
#                    total: 214684
#                    target: null
                percent = (progress.loaded / progress.total) * 100
                progressUpdate percent, progress
                return

        remove: (id) ->
            sm = @

            $http.delete server+'/'+id
            .success (res) ->
                sm.response = res
                return
            return

        getData: -> data
    service


angular.module 'app'
.factory 'UploadFileFtr', UploadFileFtr
